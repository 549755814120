import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MDXPage from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import FilterPhrase from '../components/FilterPhrase';
import StateDropdown from '../components/StateDropdown';
import Table from '../components/Table';
import Header from '../components/Header.mdx';
import StaticNavbar from '../components/StaticNavbar.mdx';
export const remainMountedChildren = <>
    <Header mdxType="Header" />
    <StaticNavbar mdxType="StaticNavbar" />
    <FloatingNavbar anchorLinks={[{
    href: '#map',
    label: 'Map'
  }, {
    href: '#list-of-universities',
    label: 'List of Universities'
  }]} mdxType="FloatingNavbar" />
    <h2 className="text-center mt-3 mb-3">All Universities</h2>
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4>
          <span>Indigenous Land Granted to Universities in </span>
          <StateDropdown mdxType="StateDropdown" />
        </h4>} mapSubtitle="Morrill Act parcels and recipient schools" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = MDXPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageMeta title="All Universities" mdxType="PageMeta" />
    <a name="list-of-universities" />
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <FilterPhrase name="universities-in-state-raised" mdxType="FilterPhrase" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Table name="universities-for-selected-grant-states" pageSize={20} mdxType="Table" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      